export const styleListGrid = {
    data(){
        return {
            activeView: "list",
            typeView: {
                list: 'list',
                grid: 'grid'
            }
        }
    },
    methods: {
        changeActiveView(){
            this.activeView = this.activeView == this.typeView.list 
                ? this.typeView.grid 
                : this.typeView.list
        },
    }
  }