<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb activeTitle="Proyectos" />
        </b-col>
        <b-col class="hp-flex-none w-auto mt-24">
          <b-row>
            <b-col class="hp-flex-none w-auto pr-0">
              <b-button
                v-show="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [ROLES.superadmin])
                "
                @click="$refs.createProject.show(null)"
                variant="primary"
                class="btn-ghost"
                >Agregar Proyecto</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" class="mb-32">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h2 class="mb-0">Proyectos</h2>
          <h5 class="mb-0 text-black-80 hp-text-color-dark-30">
            Listado de todos los proyectos
          </h5>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <b-form-select
            v-model="filters.order_by"
            :options="optionsSort"
            id="sortOptions"
            @change="getProjectsData()"
            class="mx-2"
            value-field="id"
            text-field="name"
          ></b-form-select>
          <b-button
            size="small"
            variant="outline-warning"
            class="px-1 float-right"
            @click="changeActiveView"
          >
            <i
              class="lh-2 mx-2"
              :class="{
                'ri-list-ordered-line': activeView == typeView.grid,
                'ri-grid-line': activeView == typeView.list,
              }"
            ></i>
          </b-button>
        </div>
      </div>
    </b-col>

    <b-col cols="12" class="mb-32">
      <b-row>
        <b-col cols="12" xl="3" md="3" sm="12">
          <b-form-input
            v-model="filters.name"
            type="text"
            @input="getProjectsData()"
            placeholder="Buscar proyectos por nombre"
          ></b-form-input>
        </b-col>
        <b-col cols="12" xl="3" md="3" sm="12">
          <b-form-input
            v-model="filters.code"
            type="text"
            @input="getProjectsData()"
            placeholder="Buscar por código (CECO)"
          ></b-form-input>
        </b-col>
        <b-col cols="12" xl="3" md="3" sm="12">
          <b-form-select
            v-model="filters.business_unit"
            :options="wrappedBusinessUnits"
            placeholder="Buscar por Unidad de Negocio"
            @change="getProjectsData()"
            class="mb-1"
            value-field="sap_code"
            text-field="name_code"
          ></b-form-select>
        </b-col>
        <b-col cols="12" xl="3" md="3" sm="12">
          <b-form-select
            v-model="filters.project_type"
            :options="wrappedTypesProject"
            id="projectType"
            @change="getProjectsData()"
            class="mb-1"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-col>
        <b-col cols="12" xl="3" md="3" sm="12">
          <b-form-checkbox
            v-model="filters.only_uncompleted"
            @change="getProjectsData()"
            :value="true"
            :unchecked-value="false"
            class="mt-2"
          >
            <span class="mx-1">Proyectos con plantillas pendientes</span>
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" xl="3" md="3" sm="12"> </b-col>
        <b-col cols="12" xl="3" md="3" sm="12" class="mt-1">
          <b-spinner
            v-if="loadingProjects"
            variant="warning"
            class="mt-3"
          ></b-spinner>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12">
      <div v-if="loading" class="mx-auto">
        <center>
          <b-spinner
            style="width: 7rem; height: 7rem"
            variant="warning"
            class="mx-auto"
          ></b-spinner>
        </center>
      </div>
      <b-row v-else-if="wrappedProjects.length">
        <b-col
          cols="12"
          :md="activeView == typeView.list ? 12 : 6"
          :xl="activeView == typeView.list ? 12 : 4"
          :key="index"
          v-for="(item, index) in wrappedProjects"
          class="pb-16"
        >
          <card-basic
            v-if="activeView == typeView.grid"
            @click="goToShow(item)"
            :title="item.name"
            :desc="`${item.business_unit ? `${item.business_unit}` : '-'}`"
            :subtitle="item.project_type.name"
            :code="item.code"
            :img="item.image"
          ></card-basic>
          <large-card
            v-else
            @click="goToShow(item)"
            :size-large="true"
            :title="item.name"
            :subtitle="`${item.business_unit ? `${item.business_unit}` : '-'}`"
            :desc="item.project_type.name"
            :code="item.code"
            :img="item.image"
          ></large-card>
        </b-col>
      </b-row>
      <h5 v-else class="text-center mt-16">Sin proyectos actualmente</h5>
    </b-col>

    <b-col cols="12">
      <div class="d-flex justify-content-center mt-5">
        <b-pagination
          v-model="filters.page"
          :per-page="12"
          :total-rows="projectsPaginated.items"
        ></b-pagination>
      </div>
    </b-col>
    <update-or-create-project
      ref="createProject"
      @success="getProjects(filters)"
    ></update-or-create-project>
  </b-row>
</template>

<script>
import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import CardBasic from "@/view/components/common/cards/CardBasic.vue";
import { mapActions, mapGetters } from "vuex";
import UpdateOrCreateProject from "./updateOrCreateProject.vue";
import { verifyRole } from "@/mixins/role";
import { styleListGrid } from "@/mixins/styleListGrid";
import LargeCard from "@/view/components/common/cards/LargeCard.vue";

export default {
  mixins: [verifyRole, styleListGrid],
  data() {
    return {
      loading: false,
      loadingProjects: false,
      filters: {
        name: "",
        code: "",
        order_by: "-created_at",
        project_type: null,
        section: null,
        business_unit: null,
        only_uncompleted: false,
        page: 1,
        size: 9,
      },
      optionsSort: [
        {
          id: null,
          name: "Ordenar por..",
        },
        {
          id: "-created_at",
          name: "Fecha de creación (Más recientes)",
        },
        {
          id: "created_at",
          name: "Fecha de creación (Más antiguos)",
        },
        {
          id: "code",
          name: "Código del proyecto (Orden alfabético A-Z)",
        },
        {
          id: "-code",
          name: "Código del proyecto (Orden alfabético Z-A)",
        },
      ],
      debounce: null,
      debounceTime: 400,
    };
  },
  components: {
    Breadcrumb,
    PageTitle,
    CardBasic,
    UpdateOrCreateProject,
    LargeCard,
  },
  async mounted() {
    this.loading = true;
    await this.getProjectsData();
    await this.getSections();
    await this.getBusinessUnitsFromMsMaterials();
  },
  methods: {
    ...mapActions({
      getProjects: "project/getProjects",
      getProjectsPaginated: "project/getProjectsPaginated",
      getSections: "project/getSections",
      getBusinessUnitsFromMsMaterials: "tools/getBusinessUnitsFromMsMaterials",
    }),
    async getProjectsData() {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function () {
        self.loadingProjects = true;
        await self.getProjectsPaginated({
          ...self.filters,
          only_uncompleted:
            self.filters.only_uncompleted !== false
              ? self.filters.only_uncompleted
              : undefined,
          business_unit: self.filters.business_unit
            ? self.filters.business_unit
            : undefined,
          name: self.filters.name ? self.filters.name : undefined,
          code: self.filters.code ? self.filters.code : undefined,
          sections: self.filters.section ? self.filters.section : undefined,
        });
        self.loading = false;
        self.loadingProjects = false;
      }, self.debounceTime);
    },
    goToShow(item) {
      this.$router.push({
        name: "show-project-places",
        params: {
          id: item.id,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      projects: "project/projects",
      projectsPaginated: "project/projectsPaginated",
      typesProject: "project/typesProject",
      users: "user/users",
      currentUser: "auth/currentUser",
      sections: "project/sections",
      businessUnits: "tools/businessUnits",
    }),
    wrappedProjects() {
      return this.projectsPaginated.data.map((project) => {
        return {
          ...project,
          image: project.image
            ? project.image
            : require("@/assets/img/app/projects/solar-panel.png"),
        };
      });
    },
    wrappedTypesProject() {
      return [
        { id: null, name: "Filtrar por tipo de proyecto" },
        ...this.typesProject,
      ];
    },
    wrappedSections() {
      return [{ id: null, name: "Filtrar por Sección" }, ...this.sections];
    },
    wrappedBusinessUnits() {
      return [
        { id: null, name_code: "Filtrar por Unidad de Negocio" },
        ...this.businessUnits,
      ];
    },
  },
  watch: {
    "filters.page": function (page) {
      this.getProjectsData();
    },
  },
};
</script>
